import Link from 'next/link'
import { PageNavigation } from '@sh24/ui-components'

const PageNavLink = ({ page, direction, text }) => (
  <Link href={page.url} passHref prefetch={false}>
    <a>
      <PageNavigation.Button
        direction={direction}
        text={text}
        title={page.title}
      />
    </a>
  </Link>
)

export const getNavPages = (page) => {
  const pages = {
    prevPage: null,
    nextPage: null,
  }

  const { childPageOrder } = page?.subLevel || {}
  if (!childPageOrder) return pages

  const thisPageIndex = childPageOrder.findIndex((item) => item.url === page.url)
  if (thisPageIndex === -1) return pages

  pages.prevPage = childPageOrder[thisPageIndex - 1] || null
  pages.nextPage = childPageOrder[thisPageIndex + 1] || null

  return pages
}

const buildPageNav = (page, translations) => {
  const { prevPage, nextPage } = getNavPages(page)

  if (!prevPage && !nextPage) return null

  const prevPageLink = prevPage
    ? (
      <PageNavLink
        page={prevPage}
        direction={PageNavigation.BACK}
        text={translations.previous}
      />
    )
    : null

  const nextPageLink = nextPage
    ? (
      <PageNavLink
        page={nextPage}
        direction={PageNavigation.FORWARD}
        text={translations.next}
      />
    )
    : null

  return {
    prevPageLink,
    nextPageLink,
  }
}

export default buildPageNav
