import InlineLink from '../components/InlineLink/inline-link'

const buildInlineLink = (page) => (
  <InlineLink key={page.slug} url={page.url} text={page.title} />
)

const buildBreadcrumbLinks = (page) => {
  const parent = page?.parent || page?.category
  const subLevel = page?.subLevel || page?.subCategory

  return [
    parent ? buildInlineLink(parent) : null,
    subLevel ? buildInlineLink(subLevel) : null,
  ].filter((element) => element)
}

export default buildBreadcrumbLinks
