import NextHead from 'next/head'
import addUrlsToSchemas from './utils/add-urls-to-schemas'

const JSONSchema = ({
  Head = NextHead,
  id,
  url,
  schema,
}) => {
  if (!schema || schema.length === 0) return null
  const schemasWithUrls = addUrlsToSchemas(schema, url)
  return (
    /* eslint-disable react/no-danger */
    <Head key={id}>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(schemasWithUrls) }}
      />
    </Head>
    /* eslint-enable react/no-danger */
  )
}

export default JSONSchema
