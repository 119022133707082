/**
 * Determine the correct colour for the given module section based on
 * patterns defined in config.
 *
 * Can be overriden for specific modules.
 *
 * @param {object} colours - colour config
 * @param {array<string>} colours.middle - list of colour patterns
 * @param {string} colours.last - last colour to use
 * @param {object} overrides - colour overrides for given modules
 * @param {string} moduleType - module identifier for overrides
 * @param {number} index - current module index
 * @param {boolean} last - indicates last module
 * @returns {string}
 */
export const getBackgroundColour = ({
  colours,
  overrides = {},
  moduleType,
  index,
  last,
}) => {
  const totalColours = colours.middle.length
  const colourIndex = index % totalColours

  const bgColourOverride = overrides[moduleType]

  if (bgColourOverride) return bgColourOverride

  return last ? colours.last : colours.middle[colourIndex]
}

/**
 * Determine the correct decoration for the given module section based on
 * patterns defined in config.
 *
 * Ensures the last section has no decoration.
 *
 * @param {object} decorations - decoration config
 * @param {array<string>} decorations.middle - list of decortion patterns
 * @param {number} index - current module index
 * @param {boolean} last - indicates last module
 * @returns {string}
 */
export const getDecoration = ({
  decorations,
  index,
  last,
}) => {
  const totalDecorations = decorations.middle.length
  const decorationIndex = index % totalDecorations

  return last ? 'None' : decorations.middle[decorationIndex]
}
