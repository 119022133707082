import { useRouter } from 'next/router'
import {
  BreakpointHelpers,
  Content,
  Page,
  PageTitle,
} from '@sh24/ui-components'
import styled from 'styled-components'
import useTranslations from '../../utils/use-translations'
import documentToReactComponents from '../../utils/document-to-react-components'
import BackNavigation from '../../components/BackNavigation/back-navigation'
import Image from '../../components/Image/image'
import { PersonSnippetModule } from '../../components/Modules'
import { formatDate } from '../../utils/helpers'

const { tabletUp } = BreakpointHelpers

const LowerSection = styled.div`
  ${({ theme, hasAuthor }) => `
    display: flex;
    gap: ${theme?.spacing?.lg};
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    ${tabletUp(`
      gap: ${theme?.spacing?.md};
      flex-direction: ${hasAuthor ? 'row-reverse' : 'row'};
      flex-wrap: nowrap;
    `)}
  `}

`

const AuthorContainer = styled.div`
  width: 100%;
  flex-shrink: 0;
  margin-top: ${({ theme }) => theme?.spacing?.md};

  ${tabletUp(`
    margin-top: 0;
    width: 220px;
  `)}
`

const PublishDate = styled.div`
  color: ${({ theme }) => theme?.palette?.black400};
`

const BlogHeader = ({ page, config, backPath }) => {
  const { locale } = useRouter()
  const translations = useTranslations()
  const publishedPrefix = translations['page.publishedAt'] || ''
  const published = formatDate({ dateString: page.publishedAt, locale })

  const header = [
    page.title ? <PageTitle key={page.title} text={page.title} size="h1" /> : null,
    (page.headerContent ? (
      <div key="header-content" className="heading-3 mt-base">
        {documentToReactComponents(page.headerContent)}
      </div>
    ) : null),
    <PublishDate key="header-publish-date" className="mt-md body-sm">
      {publishedPrefix} {published}
    </PublishDate>,
  ]

  const HeaderImage = page.image ? (
    <Image
      image={page.image}
      maxWidth={560}
      maxHeight={315}
      layout="intrinsic"
    />
  ) : null

  return (
    <Page.Section
      backgroundColour={config.backgroundColours.first}
      decoration={config.decorations.first}
    >
      <Page.Content>
        <BackNavigation page={page} width="standard" backPath={backPath} />

        <Content width="standard" textAlign={config.textAlign} content={header} />

        <div className="row row-centered">
          <div className="col col-12 col-md-10 col-lg-8">
            <LowerSection className="mt-md" hasAuthor={!!page.author}>
              {page.author && (
                <AuthorContainer>
                  <PersonSnippetModule
                    /* eslint-disable-next-line jsx-a11y/aria-role */
                    role="writer"
                    {...page.author}
                    url={null}
                    shortBio={page.author.jobTitle}
                  />
                </AuthorContainer>
              )}

              {HeaderImage}
            </LowerSection>
          </div>
        </div>
      </Page.Content>
    </Page.Section>
  )
}

export default BlogHeader
