import { gtmEvent } from '../../../services/gtm'
import basicPenceToString from '../../../utils/basic-pence-to-string'

const trackAddRemoveFromBasket = (action, identifier, context) => {
  const { products, currency } = context
  const product = products.find((p) => p.identifier === identifier)

  const data = {
    ecommerce: {
      currencyCode: currency,
      [action]: {
        products: product
          ? [{
            id: identifier,
            name: product.productName,
            price: basicPenceToString(product.price),
            brand: product.productName,
            quantity: 1,
          }]
          : [],
      },
    },
  }

  const actionName = {
    add: 'Add to basket',
    remove: 'Remove from basket',
  }

  gtmEvent({
    event: 'ecommerce_event',
    action: actionName[action],
    category: 'Ecommerce',
    label: product.productName,
    value: basicPenceToString(product.price),
    data,
  })
}

export default trackAddRemoveFromBasket
