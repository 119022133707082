export default {
  textAlign: 'left',
  textWidth: 'standard',
  backgroundColours: {
    first: '/white',
    middle: [
      '/white',
    ],
    last: '/white',
  },
  backgroundOverrides: {
    orderSummary: '/tertiary200',
  },
  decorations: {
    first: 'None',
    middle: [
      '/curve/quarter/leftInverse',
      '/curve/quarter/left',
      '/curve/semiInverse',
      '/curve/semi',
    ],
  },
  modules: {},
}
