import { gtmEvent } from '../../../services/gtm'
import basicPenceToString from '../../../utils/basic-pence-to-string'

const trackEcommercePurchase = (ctx) => {
  const {
    basket,
    paymentIntent,
    totals,
    currency,
    productId,
  } = ctx
  const timestamp = new Date().getTime()
  const id = paymentIntent?.id || `free-order-${timestamp}`
  const revenue = basicPenceToString(paymentIntent?.amount)

  const data = {
    ecommerce: {
      currencyCode: currency,
      purchase: {
        actionField: {
          id,
          revenue,
          coupon: totals?.promoDiscount?.label || '',
        },
        products: basket?.length > 0
          ? basket.map((item) => ({
            id: item.identifier,
            name: item.name,
            price: basicPenceToString(item.price),
            brand: item.productName,
            quantity: 1,
          }))
          : [],
      },
    },
    paymentMethod: paymentIntent?.paymentMethod || '',
    initialProduct: (productId || '').replace(/-/g, ' '),
  }

  gtmEvent({
    event: 'ecommerce_event',
    action: 'Purchase',
    category: 'Ecommerce',
    label: id,
    value: revenue,
    data,
  })
}

export default trackEcommercePurchase
