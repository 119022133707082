import { ButtonLink, Breadcrumb } from '@sh24/ui-components'
import InlineLink from '../InlineLink/inline-link'
import buildBreadcrumbLinks from '../../utils/build-breadcrumb-links'

const NAV = {
  BREADCRUMBS: 'breadcrumbs',
  BACKLINK: 'backLink',
}

const BackNavigation = ({ page, width, backPath }) => {
  const { navigation, backLinkText } = page
  const breadcrumbLinks = buildBreadcrumbLinks(page)

  if (navigation === NAV.BREADCRUMBS) {
    return <Breadcrumb breadcrumbLinks={breadcrumbLinks} width={width} />
  }

  if (navigation === NAV.BACKLINK) {
    const lastBreadcrumb = breadcrumbLinks[breadcrumbLinks.length - 1]
    const url = backPath || lastBreadcrumb?.props.url || null
    const text = backLinkText || lastBreadcrumb?.props.text || null

    if (!url || !text) return null

    return (
      <div className="row row-centered pb-md">
        <div className={`col col-12 col-md-${width === 'wide' ? '8' : '6'}`}>
          <div>
            <ButtonLink variation="text">
              <InlineLink
                url={url}
                text={text}
                iconName="arrow-left"
                iconPosition="left"
              />
            </ButtonLink>
          </div>
        </div>
      </div>
    )
  }

  return null
}

export default BackNavigation
