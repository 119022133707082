const addUrlsToSchemas = (schemas, url) => schemas.map((obj) => {
  if (obj?.mainEntityOfPage && typeof window !== 'undefined') {
    return {
      ...obj,
      mainEntityOfPage: {
        ...obj.mainEntityOfPage,
        '@id': `${window.location.origin}${url}`,
      },
    }
  }
  return obj
})

export default addUrlsToSchemas
